import React from 'react';
import { Link } from 'react-router-dom';
const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

function Roadtrip() {
    return (
        <Link to="/roadtrip" className='logo' smooth={true} duration={2000} onClick={scrollToTop}>
            <div id='mainroadtrip'>
                <div className='nameroadtrip'>
                    <h1>CLIQUE ICI</h1>
                    <h2>POUR DÉCOUVRIR LE PROJET 8674KM</h2>
                    {/* <div className='header-btnsroadtrip'>
                        <button className='header-btnroadtrip'>Réservation</button>
                    </div> */}
                </div>
            </div>
        </Link>
    );
};

export default Roadtrip;
