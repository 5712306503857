import React from 'react';
import Navbar from '../components/App/Navbar';
import Not from '../components/App/404.jsx';
import Footer from '../components/App/Footer'

const NotFound = () => {
    return (
        <div>
            <Navbar></Navbar>
            <Not />
            <Footer />
        </div>
    );
}
export default NotFound;
