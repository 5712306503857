import React from 'react';
import { CounterAnimation1, CounterAnimation2, CounterAnimation } from './Animation';
import { motion } from 'framer-motion/dist/framer-motion';
import bg1 from '../../images/lucas3.png';
import bg2 from '../../images/lucas2.png';
import bg3 from '../../images/lucas4.png';
const boxesData = [
  { title: 'DÉCOUVERTE', text: 'DÉCOUVRIR CES PAYS MAGNIFIQUE DANS UNE EXPÉRIENCE À 100% EN AUTONOMIE, PLANTER MA TENTE DANS DES ENDROITS INSOLITE.', counterComponent: <CounterAnimation />, backgroundImage: bg1 },
  { title: 'RÉALISATION', text: 'PROPOSER DU CONTENU ADAPTÉ AUX RÉSEAUX SOCIAUX DANS UN FORMAT PORTRAIT OU PAYSAGE. MUNI DE MA CAMÉRA, MES APPAREILS PHOTO, MES GOPRO ET MON DRONE, MON CONTENU SERA DICTÉ PAR UN STYLE CINÉMATOGRAPHIQUE ET SERA POSTÉ CHAQUE JOUR.', counterComponent: <CounterAnimation2 />, backgroundImage: bg2 },
  { title: 'PASSION', text: 'PASSIONNÉ DE PHOTOS ET VIDÉOS, CE VOYAGE EST AUSSI UNE PASSERELLE POUR M’OUVRIR DES PORTES DANS CE DOMAINE ET VIVRE PLEINEMENT DE MA PASSION.', counterComponent: <CounterAnimation1 />, backgroundImage: bg3 },
];

const containerVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { staggerChildren: 0.5 } },
};

const boxVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0 },
};

function Compteur() {
  return (
    <div className='bail'>
      <h1>MES OBJECTIFS</h1>
      <div id='obj'>
        <motion.div className='a-container-obj' initial='hidden' animate='visible' variants={containerVariants}>
          {boxesData.map((box, index) => (
            <motion.div key={index} className='a-box-obj' variants={boxVariants} style={{ backgroundImage: `url(${box.backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
              <div className='a-b-text-obj'>
                <h2>{box.title}</h2>
                <p>{box.text}</p>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </div>
  );
}

export default Compteur;
