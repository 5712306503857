import React from 'react';
import ReactDOM from 'react-dom';
import '../src/css/index.css';
import '../src/css/contact.css';
import '../src/css/shooting.css';
import '../src/css/roadtrip.css';
import App from './App';
import Contact from './pages/Contact';
import Shooting from './pages/Shooting';
import Roadtrip from './pages/Roadtrip';
import NotFound from './pages/Notfound';

import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";



const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/shooting",
    element: <Shooting />,
  },
  {
    path: "/roadtrip",
    element: <Roadtrip />,
  },
  {
    path: "*", // Route catch-all
    element: <NotFound />,
  },
]);

// ReactDOM.render(
//   <>
//     <App />
//   </>,
//   document.getElementById('root')
// );

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
