import React from 'react';
import video1 from '../../videos/dolomite.mp4'

function Header() {
    return (
        <div id='main-roadtrip'>
            <video src={video1} autoPlay loop muted />
            <div className='name-roadtrip'>
                <h1>8674KM</h1>
            </div>
        </div>
    );
};

export default Header;
