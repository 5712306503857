import React from 'react';

function Header() {
    return (
        <div id='main-galerie'>
            <div className='name-galerie'>
                {/* <h1>CONTACT</h1> */}
            </div>
        </div>
    );
};

export default Header;
