import React, { useState, useEffect } from 'react';
import logo from '../../images/logo/logo1.png';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 50) {
      setNav(true);
    } else {
      setNav(false);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', changeBackground);
    return () => {
      window.removeEventListener('scroll', changeBackground);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <nav className={nav ? "nav active" : "nav"}>
      <Link to="/" className='logo' duration={2000} onClick={scrollToTop}>
        <img src={logo} alt=''></img>
      </Link>
      <input className='menu-btn' type='checkbox' id="menu-btn" />
      <label className='menu-icon' htmlFor='menu-btn'>
        <span className='nav-icon'></span>
      </label>
      <ul className='menu'>
        <li>
          <Link to="/roadtrip" smooth={true} duration={2000} onClick={scrollToTop}>8674KM</Link>
        </li>
        <li>
          <Link to="/shooting" smooth={true} duration={2000} onClick={scrollToTop}>Réalisations</Link>
        </li>
        {/* <li>
          <Link to="/media">Média</Link>
        </li> */}
        <li>
          <Link to="/contact" smooth={true} duration={2000} onClick={scrollToTop}>Contact</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
