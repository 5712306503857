import React from 'react';
import Navbar from './components/App/Navbar';
import About from './components/App/About';
import Footer from './components/App/Footer';
import Bandeau from './components/App/Bandeau';
import AboutRoad from './components/App/AboutRoad';
import Roadtrip from './components/App/Roadtrip';
import Bandeaubis from './components/App/Bandeaubis';

const App = () => {
  return (
      <div>
        <Navbar></Navbar>
        {/* <Header /> */}
        <About />
        <Bandeau />
        {/* <Feature /> */}
        {/* <Offer /> */}
        {/* <Social /> */}
        <AboutRoad />
        <Roadtrip />
        <Bandeaubis />
        {/* <Contact /> */}
        <Footer />
      </div>
  );
};

export default App;
