import React from 'react';
import aboutimage from '../../videos/gardienbike.mp4'

function AboutRoad () {
    return (
        <div id='aboutroad'>
            <div className='aboutroad-text'>
                <h1>L'APPEL DE L'AVENTURE</h1>
                <p>EN TANT QUE MOTARD PASSIONNÉ, J'AI EU LA CHANCE D'EXPLORER LES CHEMINS SINUEUX ET PITTORESQUES DE SUISSE, DE FRANCE ET D'ITALIE. CHAQUE KILOMÈTRE PARCOURU À MOTO EST UNE AVENTURE INOUBLIABLE, MARQUÉE PAR LA LIBERTÉ ET L'ÉXCITATION DE LA ROUTE.</p>
                <p>AYANT SOUVENT VOYAGÉ EN GROUPE, JE RESCENS UNE ENVIE DE REPOUSSER MES LIMITES DANS UNE AVENTURE EN SOLITAIRE EN CRÉANT LE PROJET 8674KM.</p>
                {/* <button>Lire plus</button> */}
            </div>
            <div className='aboutroad-video'>
                <video src={aboutimage} autoPlay loop muted></video>
            </div>
            
        </div>
    );
}

export default AboutRoad;
