import React from 'react';
import video1 from '../../videos/collab1.mp4'
import video2 from '../../videos/collab2.mp4'
import video3 from '../../videos/collab3.mp4'
import video4 from '../../videos/trip1.mp4'
import video5 from '../../videos/trip2.mp4'
import video6 from '../../videos/trip3.mp4'
import video7 from '../../videos/lavage.mp4'
import video8 from '../../videos/night.mp4'
import video9 from '../../videos/r1.mp4'
import video10 from '../../videos/lifeonmoto.mp4'
import video11 from '../../videos/gardienbike.mp4'
import video12 from '../../videos/liberty.mp4'

// import { Link } from 'react-router-dom';

function Social () {
    return (
        <div id="features-offres">
            <h1>LIFE ON A MOTORCYCLE</h1>
            <div className='a-container-offres'>
                <div className='a-box-offres'>
                    <div className='a-b-img-offres'>
                        <video src={video10} autoPlay loop muted playsinline preload/>
                    </div>
                    <div className='a-b-text-offres'>
                        <h2>Cinematic Vlog</h2>
                        <p>Réalisation & montage @gardiendusansplomb</p>
                        <p>Honda Transalp XL750 @gardiendusansplomb</p> 
                    </div>
                </div>
                <div className='a-box-offres'>
                    <div className='a-b-img-offres'>
                        <video src={video11} autoPlay loop muted playsinline preload/>
                    </div>
                    <div className='a-b-text-offres'>
                        <h2>Are you ready for the adventure ?</h2>
                        <p>Réalisation & montage @gardiendusansplomb</p>
                        <p>Honda Transalp XL750 @gardiendusansplomb</p>   
                    </div>
                </div>
                <div className='a-box-offres'>
                    <div className='a-b-img-offres'>
                        <video src={video12} autoPlay loop muted playsinline preload/>
                    </div>
                    <div className='a-b-text-offres'>
                        <h2>A feeling of liberty</h2>
                        <p>Réalisation @nathgrbl & montage @gardiendusansplomb</p>
                        <p>Honda Transalp XL750 @gardiendusansplomb</p>   
                    </div>
                </div>
            </div>
            <h1><span className='gardien'>GARDIENDUSANSPLOMB</span> x <span className='bonmalin'>Bonmalin</span></h1>
            <div className='a-container-offres'>
                <div className='a-box-offres'>
                    <div className='a-b-img-offres'>
                        <video src={video1} autoPlay loop muted playsinline/>
                    </div>
                    <div className='a-b-text-offres'>
                        <h2>BM x GSP Part. 1 (Intro)</h2>
                        <p>Réalisation & montage @gardiendusansplomb</p>
                        <p>BMW 320i @gardiendusansplomb</p> 
                    </div>
                </div>
                <div className='a-box-offres'>
                    <div className='a-b-img-offres'>
                        <video src={video2} autoPlay loop muted playsinline/>
                    </div>
                    <div className='a-b-text-offres'>
                        <h2>BM x GSP Part. 2</h2>
                        <p>Réalisation & montage @gardiendusansplomb @bonmalin</p>
                        <p>BMW 320i @gardiendusansplomb</p>   
                    </div>
                </div>
                <div className='a-box-offres'>
                    <div className='a-b-img-offres'>
                        <video src={video3} autoPlay loop muted playsinline/>
                    </div>
                    <div className='a-b-text-offres'>
                        <h2>BM x GSP Part. 3 (Fin)</h2>
                        <p>Réalisation & montage @gardiendusansplomb</p>
                        <p>BMW 320i @gardiendusansplomb</p>    
                    </div>
                </div>
            </div>
            <h1>MINI OLD TRIP</h1>
            <div className='a-container-offres'>
                <div className='a-box-offres'>
                    <div className='a-b-img-offres'>
                        <video src={video4} autoPlay loop muted playsinline/>
                    </div>
                    <div className='a-b-text-offres'>
                        <h2>Le Morvan en automne…</h2>
                        <p>Réalisation & montage @gardiendusansplomb</p>
                        <p>E30-E36-VR6-GTI</p> 
                    </div>
                </div>
                <div className='a-box-offres'>
                    <div className='a-b-img-offres'>
                        <video src={video5} autoPlay loop muted playsinline/>
                    </div>
                    <div className='a-b-text-offres'>
                        <h2>Beautiful spot with beautiful cars</h2>
                        <p>Réalisation & montage @gardiendusansplomb</p>
                        <p>E30-E36-VR6-GTI</p>  
                    </div>
                </div>
                <div className='a-box-offres'>
                    <div className='a-b-img-offres'>
                        <video src={video6} autoPlay loop muted playsinline/>
                    </div>
                    <div className='a-b-text-offres'>
                        <h2>Perfect road-trip car?</h2>
                        <p>Réalisation & montage @gardiendusansplomb</p>
                        <p>BMW E30 Touring 320i @cebrattila</p>  
                    </div>
                </div>
            </div>
            <h1>HORS SERIES</h1>
            <div className='a-container-offres'>
                <div className='a-box-offres'>
                    <div className='a-b-img-offres'>
                        <video src={video7} autoPlay loop muted playsinline/>
                    </div>
                    <div className='a-b-text-offres'>
                        <h2>Just a good moment for your car</h2>
                        <p>Réalisation & montage @gardiendusansplomb</p>
                        <p>BMW 320i @gardiendusansplomb</p> 
                    </div>
                </div>
                <div className='a-box-offres'>
                    <div className='a-b-img-offres'>
                        <video src={video8} autoPlay loop muted playsinline/>
                    </div>
                    <div className='a-b-text-offres'>
                        <h2>it’s a Old Rainy Night !</h2>
                        <p>Réalisation & montage @gardiendusansplomb</p>
                        <p>E30-GTI-E36 </p>   
                    </div>
                </div>
                <div className='a-box-offres'>
                    <div className='a-b-img-offres'>
                        <video src={video9} autoPlay loop muted playsinline/>
                    </div>
                    <div className='a-b-text-offres'>
                        <h2>CROSSPLANE</h2>
                        <p>Réalisation & montage @gardiendusansplomb</p>
                        <p>Yamaha R1 @croustiman</p>   
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Social;
