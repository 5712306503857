import React from 'react';
import aboutimage from '../../images/map.png'

function Map () {
    return (
        <div id='map'>
            <div className='map-image'>
                <img src={aboutimage} alt='map'></img>
            </div>
            <div className='map-text'>
                <h1>ITINÉRAIRE</h1>
                <p>DÉPART DE PARIS, JE SOUHAITE REJOINDRE LA GRÈCE EN PASSANT PAR LA SUISSE, L'ITALIE, LA SLOVENIE, LA CROATIE, LA BOSNIE, LE MONTENEGRO, LE KOSOVO ET L'ALBANIE EN EMPRUNTANT DES ROUTES MAIS SURTOUT DES PISTES ET DES CHEMINS.</p>
                <p>APRÈS AVOIR PARCOURU CES DIFFÉRENTS PAYS, JE SOUHAITE REJOINDRE LA SICILE EN FERRY, LA SARDAIGNE, L'ESPAGNE PUIS LE PORTUGAL POUR ENFIN REMONTER À PARIS.</p>
                {/* <button>Lire plus</button> */}
            </div>
        </div>
    );
}

export default Map;
