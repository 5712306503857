import React from 'react';
import Navbar from '../components/App/Navbar';
import Header from '../components/Shooting/Header';
import Footer from '../components/App/Footer';
import Social from '../components/Shooting/Social';

const Shooting = () => {
    return (
        <div>
            <Navbar></Navbar>
            <Header />
            {/* <Offer />
            <Compteur /> */}
            <Social />
            <Footer />
        </div>
    );
}

export default Shooting;
