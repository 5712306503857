import React from 'react';
import aboutimage from '../../images/profil.jpeg'

function About () {
    return (
        <div id='about'>
            <div className='about-image'>
                <img src={aboutimage} alt='about'></img>
            </div>
            <div className='about-text'>
                <h1>QUI EST DERRIÈRE GARDIENDUSANSPLOMB ?</h1>
                <p>JE M'APPELLE LUCAS DELORY ET M'APPRÊTE À TERMINER MES ÉTUDES.</p>
                <p>PASSIONNÉ DE MOTO, D'AUTOMOBILE MAIS ÉGALEMENT DE VIDEOGRAPHIE ET PHOTOGRAPHIE, J'AI DÉCIDÉ IL Y A QUELQUE TEMPS DE ME LANCER DANS LA CRÉATION DE CONTENU. POUR CELA, J'AI CRÉÉ <span className='gardienp'>GARDIENDUSANSPLOMB</span> EN PARALLÈLE DE MES ÉTUDES.</p>
                <p>CE CONTENU MET EN IMAGE LA PASSION AUTOMOBILE ET SURTOUT DE L'AVENTURE À MOTO QUE CE SOIT SUR ROUTE OU EN <span className='gardienp'>OFF-ROAD.</span></p>
                {/* <button>Lire plus</button> */}
            </div>
            
        </div>
    );
}

export default About;
