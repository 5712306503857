import React from 'react';
import video1 from '../../videos/edit.mp4'
function Header() {
    return (
        <div id='main-offres'>
            <video src={video1} autoPlay loop muted playsinline preload/>
            <div className='name-offres'>
                <h1>RÉALISATIONS</h1>
            </div>
        </div>
    );
};

export default Header;
