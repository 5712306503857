import React from 'react';
import Navbar from '../components/App/Navbar';
import Header from '../components/Contact/Header';
import Contacte from '../components/Contact/Contact.jsx'
import Footer from '../components/App/Footer'

const Contact = () => {
    return (
        <div>
            <Navbar></Navbar>
            <Header />
            <Contacte />
            {/* <Offer /> */}
            <Footer />
        </div>
    );
}
export default Contact;
