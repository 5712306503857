import React from 'react';

function Bandeaubis() {
  return (
        <div id='bandeaubis'>
            <div className='bandeaubis'>
                <h1>GARDIENDUSANSPLOMB EST LE PILOTE ULTIME POUR SUBLIMER VOS PRODUITS ET VOTRE MARQUE À TRAVERS UN CONTENU DÉDIÉ.</h1>
                {/* <h2>POUR DECOUVRIR LE PROJET 8764KM</h2> */}
            </div>
        </div>
  );
}

export default Bandeaubis;
