import React from 'react';

const BandeauTexteDefilant = ({ noms }) => {
  // Dupliquer les noms pour qu'il y en ait toujours trois qui défilent
  const nomsDupliques = [...noms, ...noms];

  return (
    <div className="bandeau-container">
      <div className="bandeau">
        {nomsDupliques.map((nom, index) => (
          <span key={index} className="bandeau-item">{nom}</span>
        ))}
      </div>
    </div>
  );
};

function App() {
  return (
    <div className="bandeaugen">
      <BandeauTexteDefilant noms={["ROULER, VOYAGER, CRÉER", "ROULER, VOYAGER, CRÉER"]} />
    </div>
  );
}

export default App;
