import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTiktok, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h2>CONTACT</h2>
          <p>gardiendusansplomb@gmail.com</p>
          <p>+33 7 81 72 55 12</p>
        </div>
        {/* <div className="footer-section">
          <h2>Nos Services</h2>
          <ul>
            <li>Service 1</li>
            <li>Service 2</li>
            <li>Service 3</li>
          </ul>
        </div> */}
        <div className="footer-section">
          <h2>SOCIAL MEDIA</h2>
          <div className="social-icons">
            <a href="https://www.instagram.com/gardiendusansplomb/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="https://www.youtube.com/@gardiendusansplomb" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faYoutube} />
            </a>
            <a href="https://www.tiktok.com/@gardiendusansplomb" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTiktok} />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Gardiendusansplomb. Tous droits réservés.</p>
      </div>
    </footer>
  );
};

export default Footer;
