import React from 'react';
import aboutimage from '../../videos/gardienbike.mp4'
import { Link } from 'react-router-dom';

function Not () {
    return (
        <div id='notfound'>
            <div className='aboutroad-text'>
                <h1>ERREUR 404</h1>
                <p>DESOLE, LA PAGE QUE VOUS RECHERCHEZ N'EXISTE PAS.</p>
                <p>POUR REVENIR SUR LA BONNE VOIE, POURQUOI NE PAS JETER UN COUP D'ŒIL A MON PROJET <Link to="/roadtrip"><span className='gardienp'>8674KM</span></Link> ?</p>
                {/* <button>Lire plus</button> */}
            </div>
            <div className='aboutroad-video'>
                <video src={aboutimage} autoPlay loop muted></video>
            </div>
            
        </div>
    );
}

export default Not;
