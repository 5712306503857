import React, { useState, useEffect } from 'react';

export const CounterAnimation = () => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setValue((prevValue) => (prevValue < 291 ? prevValue + 1 : 291));
    }, 50);

    return () => clearInterval(intervalId);
  }, []);

  return <p className="counter">{value}</p>;
};

export const CounterAnimation1 = () => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setValue((prevValue) => (prevValue < 291 ? prevValue + 1 : 291));
    }, 50);

    return () => clearInterval(intervalId);
  }, []);

  return <p className="counter">{value}</p>;
};
export const CounterAnimation2 = () => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setValue((prevValue) => (prevValue < 26 ? prevValue + 1 : 26));
    }, 50);

    return () => clearInterval(intervalId);
  }, []);

  return <p className="counter">{value}</p>;
};

export default CounterAnimation;

