import React, { useRef,useState } from 'react';
import emailjs from '@emailjs/browser';

export const Contact = () => {
  const form = useRef();
  const [notification, setNotification] = useState(null);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_yas3feg', 'template_gmt19cm', form.current, '1UtYxm01hWRfZHizk')
      .then((result) => {
          console.log(result.text);
          setNotification('Email envoyé avec succès !');
          setTimeout(() => {
            form.current.reset();
            setNotification(null);
          }, 3000);
      }, (error) => {
          console.log(error.text);
          setNotification('Erreur lors de l\'envoi de l\'email.');
          setTimeout(() => {
            form.current.reset();
            setNotification(null);
          }, 3000);
      });
  };

  return (
    <div id="contact">
        <h1>PREND CONTACT AVEC LE GARDIEN !</h1>
        {/* <div className='notification'>test</div> */}
        <form ref={form} onSubmit={sendEmail}>
            <label>Name</label>
            <input type="text" name="user_name" />
            <label>Email</label>
            <input type="email" name="user_email" />
            <label>Message</label>
            <textarea name="message" />
            <input type="submit" value="Send" />
        </form>
        {notification && <div className='notification'>{notification}</div>}
    </div>
    
  );
};
export default Contact;