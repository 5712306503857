import React from 'react';
import Navbar from '../components/App/Navbar';
import Header from '../components/Roadtrip/Header';
import Map from '../components/Roadtrip/Map';
import Obj from '../components/Roadtrip/Obj';
import Footer from '../components/App/Footer';

const Roadtrip = () => {
    return (
        <div>
            <Navbar></Navbar>
            <Header/>
            <Map />
            <Obj />
            <Footer />
        </div>
    );
}

export default Roadtrip;
